import React from "react"
import { ContentCard } from "../../layouts/ContentCard"

export interface AboutPageProps {}

const AboutPageComponent = (props: AboutPageProps) => {
	return (
		<ContentCard>
			<div className="flex flex-col">
				<h2 className="mb-5 text-4xl font-bold">About</h2>
				<p>Adundas is just a placeholder.</p>
				<p>Nothing going on here.</p>
			</div>
		</ContentCard>
	)
}

export const AboutPage = React.memo(AboutPageComponent)
